<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Ticketing from "@/services/Ticketing";
    import Http from "@/services/Http";
    import Swal from "sweetalert2";
    import Fixtures from "../../../services/Fixtures";
    import modalEditSalePeriod from "@/components/modals/ticketing/modalEditSalePeriod";

    /**
     * Starter page
     */
    export default {
        components: {
            Layout,
            PageHeader,
            modalEditSalePeriod ,

        },
        page: {
            title: "Tickets Matches Details",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Matches Details",
                items: [
                    {
                        text: "Tickets Matches",
                        href: '/ticketing/tickets_matches'
                    },
                    {
                        text: "Matches Details",
                        active: true,

                    },
                ],

                checked: false ,
                eventTeam: 0, //0 for home, 1 for away
                match_id: this.$route.query.match_id,
                remote_match_id: this.$route.query.remote_match_id,
                match: null,
                second_player_id: null,
                canEdit: false,
                match_datetime: '' ,
                timer: { minutes: 0, seconds: 0 },
                homeSubs: [],
                awaySubs: [],
                events: [],
                match_status: 0,
                homeTeam: {
                    home_team_name: "",
                    home_team_logo: "",
                    score: 0,
                    roster: [],
                },
                awayTeam: {
                    away_team_name: "",
                    away_team_logo: "",
                    score: 0,
                    roster: [],
                },
                eventStartSaleFrom: "" ,

                eventEndSaleAt: "" ,

                outOfStock: "" ,
                matchId: "" ,
                competition: {
                    competition_name : "",
                } ,

                remoteMatchId: "" ,
                rosterCreated: true,
                isBusy: false,
                error: null,
                showModal: false,
                modalData: {},
                refresh:false,
                matchExists : null
            };

        },
//        created() {
////            this.getMatch();
//            this.getMatchesTickets();
//
//        },

        created() {
        this.getMatch().then(success => {
        if (!success) {
            // If getMatch fails, fetch data from getMatchesTickets
            this.getMatchesTickets();
        }
    });
    },

        methods: {

            async getMatch() {
                if (this.match_id !== '') {
                    try {
                        const response = await Fixtures.getMatch(this.match_id);
                        this.homeTeam.home_team_name = response.data.match.home_team_name;
                        this.homeTeam.home_team_logo = response.data.match.home_team_logo;
                        this.awayTeam.away_team_name = response.data.match.away_team_name;
                        this.awayTeam.away_team_logo = response.data.match.away_team_logo;
                        this.homeTeam.score = response.data.match.score.ft_score_home || 0;
                        this.awayTeam.score = response.data.match.score.ft_score_away || 0;
                        this.match = response.data.match;
                        this.matchExists = true;
                    } catch (error) {
                        this.matchExists = false;
//
//                        if (error.response && error.response.data && error.response.data.error_details) {
//                            const errorDetails = error.response.data.error_details;
//                            if (errorDetails.error_code === 598) {
//                                this.failedmsg(errorDetails.error_message);
//
//                            } else {
//                                this.failedmsg(errorDetails.error_message);
//                            }
//                        } else {
//                            this.failedmsg("An unexpected error occurred.");
//                        }
//
//                        // Call getMatchesTickets to display its data even if getMatch fails
//                        this.getMatchesTickets();
                    }
                }
            },
            async getMatchesTickets() {
                try {
                    var filter_string = '';
                    if(this.remote_match_id) {
                        filter_string += (this.remote_match_id) ? '&remote_match_id=' + this.remote_match_id : "";
                    }

                    this.toggleBusy();
                    const response = await Ticketing.getMatchesTickets(filter_string);
                    const matchId = response.data.match_id;

                    this.awayTeam.away_team_name = response.data.data[0].away_team;
                    this.homeTeam.home_team_name = response.data.data[0].home_team;
                    this.match_datetime = response.data.data[0].match_datetime ;
                    this.eventStartSaleFrom = response.data.data[0].event_start_sale_from;
                    this.eventEndSaleAt = response.data.data[0].event_end_sale_at;
                    this.outOfStock = response.data.data[0].out_of_stock;
                    this.matchId = response.data.data[0].match_id;
                    this.competition.competition_name = response.data.data[0].competition_name;
                    this.remoteMatchId = response.data.data[0].remote_match_id;

                    this.tableData = response.data.data;
                    this.toggleBusy();
                } catch (error) {
                    console.log("HERE");
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = [];
                }
            },

            callModalEditSalePeriod(remote_match_id, out_of_stock, event_start_sale_from, event_end_sale_at) {

                this.modalData.remote_match_id = remote_match_id;
                this.modalData.out_of_stock = out_of_stock;
                this.modalData.event_start_sale_from = event_start_sale_from;
                this.modalData.event_end_sale_at = event_end_sale_at;

                this.$bvModal.show("edit_sale_period");

//                this.modalData.team_id = data.team_id;
//                this.modalData.ticketing_url = data.ticketing_url;
            },
            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 50000,
                });
            },
        },
        watch: {

            match_status(s) {
                if (s === 0 || s === 2 || s === 4) {
                    this.canEdit = false;
                } else this.canEdit = true;
            },
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div
                class="container bg-white mw-75 d-flex justify-content-center rounded p-4 shadow"
                v-if="(matchExists === null || (matchExists && match))">

            <div class="d-flex align-items-baseline">
                <div class="text-center logo-cover" v-if="homeTeam">
                    <img
                            :src="homeTeam.home_team_logo"
                            :alt="homeTeam.home_team_name"
                            class="rounded-circle w-50"
                    />
                    <p v-if="homeTeam.home_team_name">{{ homeTeam.home_team_name }}</p>
                </div>
                <div class="text-center d-flex flex-column align-items-center">
                    <div class="d-flex gap-4 align-items-end">
                        <h3>{{ homeTeam.score }}</h3>
                        <h3>-</h3>
                        <h3>{{ awayTeam.score }}</h3>
                    </div>
                    <p v-if="competition && competition.competition_name" class="text-sm mb-1">
                        {{ competition.competition_name }}
                    </p>
                    <p v-if="match && match.date_time">{{ match.date_time }}</p>
                </div>
                <div class="text-center logo-cover" v-if="awayTeam">
                    <img
                            :src="awayTeam.away_team_logo"
                            :alt="awayTeam.away_team_name"
                            class="rounded-circle w-50"
                    />
                    <p v-if="awayTeam.away_team_name">{{ awayTeam.away_team_name }}</p>
                </div>
            </div>
        </div>
        <div v-else>
            <p  style="color: white;display: inline-block; background-color: green;font-size: 14px; ">No match data available.</p>
        </div>


        <div
                class="container bg-white mw-75 d-flex justify-content-center rounded p-4 shadow"
                v-if="!match && !matchExists"
        >
            <div class="d-flex align-items-baseline">
                <div class="text-center logo-cover" v-if="homeTeam">

                    <p v-if="homeTeam.home_team_name">{{ homeTeam.home_team_name }}</p>
                </div>
                <div class="text-center d-flex flex-column align-items-center">
                        <h3>-</h3>
                    <p v-if="competition && competition.competition_name" class="text-sm mb-1">
                        {{ competition.competition_name }}
                    </p>
                    <p v-if="match_datetime">{{ match_datetime }}</p>
                </div>
                <div class="text-center logo-cover" v-if="awayTeam">

                    <p v-if="awayTeam.away_team_name">{{ awayTeam.away_team_name }}</p>
                </div>
            </div>
        </div>

<hr>

        <div class="container bg-white mw-75 d-flex justify-content-center rounded p-4 shadow">
            <!--<div class="row">-->
                <!--<h4>Sale Period</h4>-->
            <!--</div>-->
            <div class="table-responsive mt-4 mb-0">
                <div class="mt-3">
                <span style="font-size:22px; font-weight: bold; ">Sale Period</span>
                    <a href="javascript:void(0);" title="Edit Sale Period" class="px-2 text-primary float-end" @click="callModalEditSalePeriod(remoteMatchId, outOfStock, eventStartSaleFrom, eventEndSaleAt)">
                    <i class="uil uil-pen font-size-18"></i>
                    </a>
                </div>

                <div class="mt-3">
                    <p class="mb-2 text-strong">Sale Enabled :
                        <i v-if="outOfStock === null" class="fa fa-check text-success" style="font-size:14px;">Enabled</i>
                        <i v-else class="fa-fa-remove text-danger" style="font-size:14px;">Disabled</i>
                    </p>
                </div>
                <div class="mt-3">
                    <p class="mb-2 text-strong">Sale Start Date :
                        <span class="mw-80"> {{eventStartSaleFrom}}</span>
                    </p>
                </div>
                <div class="mt-3">
                    <p class="mb-2 text-strong">Sale End Date :
                        <span class="mw-80"> {{eventEndSaleAt}}</span>
                    </p>
                </div>

                <hr>

            </div>
        </div>



<!--MODALS-->
        <modalEditSalePeriod :modal_data="modalData" @onRefresh="getMatchesTickets()"></modalEditSalePeriod>


<!--END MODALS-->

    </Layout>
</template>

<style>
    .sm {
        width: 1rem;
        height: 1rem;
    }
</style>
