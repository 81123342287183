<script>
    import Ticketing from "@/services/Ticketing";


    export default {
        components:{

        },
        props: {
            modal_data: {
                type: Object,
                default: null
            },


        },
        watch: {

            modal_data: function(newVal) {
                this.out_of_stock = newVal.out_of_stock;
                this.remote_match_id = newVal.remote_match_id;
                this.event_start_sale_from = newVal.event_start_sale_from;
                this.event_start_sale_from = newVal.event_start_sale_from;
                this.checked_primary()

            }
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                remote_match_id: '',
                event_start_sale_from: '',
                event_end_sale_at: '',
                out_of_stock: '',
                isChecked : '' ,
                checked : '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                primary_checked: '',
                datetime: null,
            };
        },

        mounted() {
            // Call the function to set primary_checked based on modal_data.open_for_sale
            this.setPrimaryCheckedBasedOnSaleStatus();
        },

        methods: {

            async editSalePeriod(){
                this.tryingToEdit = true;
                this.submitted = true;

                    try {

                        await Ticketing.updateSalePeriod(this.modal_data.remote_match_id,{
                            remote_match_id: this.modal_data.remote_match_id,
                            event_start_sale_from : this.modal_data.event_start_sale_from,
                            event_end_sale_at : this.modal_data.event_end_sale_at,
                            out_of_stock: this.primary_checked == true ? 'null' : '1',

                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg("Changes has been saved");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg("Changes not saved")
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Changes not saved")
                    }

                this.tryingToEdit = false;
            },



            setPrimaryCheckedBasedOnSaleStatus() {
                // Assuming modal_data is another data property holding the relevant information
                if (this.modal_data.out_of_stock === null) {
                    this.primary_checked = true;
                } else if (this.modal_data.out_of_stock === 1) {
                    this.primary_checked = false;
                }
                console.log(this.out_of_stock);
            },

            checked_primary(){
                this.primary_checked = this.out_of_stock === null ? true : false;
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.tryingToEdit = false;
                this.submitted = false;
            }
        } ,


    };

</script>

<template>
    <b-modal v-model="showModal" id="edit_sale_period" title="Edit Sale Period" title-class="font-18" centered @hidden="resetProps"  @shown="setPrimaryCheckedBasedOnSaleStatus()">
        <!--{{modal_data}}-->
        <!--{{primary_checked}}-->
        <!--{{out_of_stock}}-->
        <form @submit.prevent="editSalePeriod">

            <!--<b-form-group label="Email" label-for="formrow-email-input" >-->
                <!--<b-form-input  v-model.trim="email_e" type="text" id="email" aria-describedby="email-feedback" disabled></b-form-input>-->
            <!--</b-form-group>-->

            <b-form-checkbox-group>
                <input type="hidden" name="remote_match_id" v-model="modal_data.remote_match_id">

                <div class="form-check form-switch form-switch-lg " >
                    <input
                              v-model="primary_checked"
                              type="checkbox"
                              class="form-check-input"
                              id="openSale"
                    />


                    <label class="form-check-label" for="openSale">Enable Sale</label>
                </div>
            </b-form-checkbox-group>


            <div class="mb-3">
                <label class="control-label form-label">Start Date</label>
                <input class="form-control" v-model="modal_data.event_start_sale_from" type="datetime-local" name="event_start_sale_from">
            </div>

            <div class="mb-3">
                <label class="control-label form-label">End Date</label>
                <input class="form-control" v-model="modal_data.event_end_sale_at" type="datetime-local" name="event_end_sale_at">
            </div>

            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editSalePeriod" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>